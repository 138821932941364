body {
  display: flex;
  height: 100vh;
  margin: 0;
  padding: 0; }

body, html {
  height: 100%; }

#app {
  width: 100%; }
